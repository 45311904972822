<template>
  <v-container
    :fluid="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? true : false"
  >
    <v-row class="pb-5 align-center">
      <v-col cols="auto">
        <v-btn
          @click="$router.go(-1)"
          plain
          :ripple="false"
          color="accent"
          small
          outlined
          icon
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <h2 class="headline font-weight-medium">{{ $t("Client File") }}</h2>
      </v-col>
    </v-row>
    <Journal
      :show-share-button="false"
      :can-access-actions="true"
      :loading="loading"
      :items="journalItems"
      :journal="journal"
      :headers="headers"
      @saveItem="onSaveItem"
      @deleteItem="onDeleteItem"
    />
  </v-container>
</template>

<script>
import Journal from "@/components/Common/Journal";
import UserMixin from "@/mixins/UserMixin";
import { mapActions } from "vuex";

export default {
  name: "ClientFile",
  mixins: [UserMixin],
  components: { Journal },

  data: () => {
    return {
      journalItems: [],
      journal: null,
      loading: false,
    };
  },
  computed: {
    headers() {
      let headers = [
        {
          text: this.$t("Date"),
          align: "start",
          sortable: true,
          value: "createdAt",
        },
        { text: this.$t("Title"), value: "title", sortable: false },
        { text: this.$t("Client File"), value: "text", sortable: false },
        { text: this.$t("Actions"), value: "actions", sortable: false },
      ];

      return headers;
    },

    userId() {
      return this.$route.params.userId;
    },
  },
  methods: {
    ...mapActions("clientFile", [
      "getUserClientFile",
      "createClientFileMessage",
      "updateClientFileMessage",
      "removeClientFileMessage",
    ]),

    async onSaveItem(item, realItem) {
      let data = {
        title: item.title,
        text: item.text,
      };

      if (item._id) {
        const updateData = data;
        await this.updateClientFileMessage({
          id: item._id,
          userId: this.userId,
          updateData,
        });
        realItem.title = data.title;
        realItem.text = data.text;
      } else {
        const createData = data;
        const res = await this.createClientFileMessage({
          userId: this.userId,
          createData,
        });
        this.journalItems.push(res);
      }
    },

    async onDeleteItem(item) {
      if (item._id) {
        await this.removeClientFileMessage({
          userId: this.userId,
          id: item._id,
        });
        const index = this.journalItems.findIndex((e) => e._id === item._id);
        if (index !== -1) {
          this.journalItems.splice(index, 1);
        }
      }
    },

    async getJournal() {
      this.loading = true;

      try {
        let res;

        res = await this.getUserClientFile({ userId: this.userId });

        this.journal = res.journal;
        this.journalItems = res.messages;
      } catch (e) {
        console.log("e.response: ", e.response);
      }

      this.loading = false;
    },
  },

  mounted() {
    this.loading = true;
    this.awaitPromiseCallback({
      key: "getMe",
      cb: async () => {
        await this.getJournal();
      },
    });
  },
};
</script>
