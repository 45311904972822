<template>
  <v-data-table
    :locale="$vuetify.rtl ? 'ar' : 'en'"
    :loading="loading"
    sort-by="date"
    :sort-desc="false"
    :headers="headers"
    :items="items"
    class="elevation-0 bg-transparent"
  >
    <template v-slot:top v-if="canAccessActions">
      <v-toolbar flat outlined rounded="lg" class="mb-4">
        <v-dialog v-model="dialog" scrollable max-width="800">
          <v-btn @click="close" elevation="2" icon class="close-icon pa-0">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <template v-slot:activator="{ on, attrs }">
            <v-layout align-center>
              <v-btn color="primary" class="me-2" dark v-bind="attrs" v-on="on">
                {{ $t("Add Note") }}
              </v-btn>

              <v-switch
                v-if="showShareButton"
                color="primary"
                :label="
                  shared ? $t('SharedWithTherapist') : $t('ShareWithTherapist')
                "
                inset
                :ripple="false"
                class="ms-5"
                hide-details
                :input-value="shared"
                @change="onSharedChange"
              >
              </v-switch>
            </v-layout>
          </template>
          <v-card>
            <!-- <v-toolbar color="primary">
              <span class="text-h5 white--text">{{ formTitle }}</span>
            </v-toolbar> -->
            <v-card-text>
              <v-container>
                <v-row class="pt-5">
                  <v-col cols="12">
                    <v-text-field
                      outlined
                      v-model="editedItem.title"
                      :label="$t('Title')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      auto-grow
                      outlined
                      v-model="editedItem.text"
                      hide-details
                      :label="$t('Note')"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions class="pb-5">
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                class="mx-2 px-5"
                outlined
                large
                @click="close"
              >
                {{ $t("Cancel") }}
              </v-btn>
              <v-btn color="primary" class="mx-2 px-5" large @click="save">
                {{ $t("Save") }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-btn
              @click="dialogDelete = false"
              elevation="2"
              icon
              class="close-icon pa-0"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-card-text class="text-center pt-5"
              ><b>{{
                $t("Are you sure you want to delete this note?")
              }}</b></v-card-text
            >
            <v-card-actions class="pb-5">
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                class="mx-2"
                outlined
                large
                @click="closeDelete"
                >{{ $t("No") }}</v-btn
              >
              <v-btn
                color="primary"
                class="mx-2"
                large
                @click="deleteItemConfirm"
                >{{ $t("Yes") }}</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:item.createdAt="{ item }">
      {{ item.createdAt | moment("DD/MM/YYYY hh:mm A") }}
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon small class="me-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <!--    <template v-slot:no-data>-->
    <!--      <v-btn color="primary" @click="initialize">-->
    <!--        Reset-->
    <!--      </v-btn>-->
    <!--    </template>-->
  </v-data-table>
</template>

<script>
const defaultItem = () => {
  return {
    date: "",
    title: "",
    text: "",
  };
};

export default {
  props: {
    showShareButton: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    canAccessActions: {
      type: Boolean,
      default: false,
    },

    items: {
      type: [Array, null],
      required: true,
    },

    journal: {
      required: true,
    },

    headers: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,

    editedItem: {
      date: "",
      title: "",
      text: "",
    },
  }),

  computed: {
    // formTitle() {
    //   return this.editedItem._id ? 'Edit Note' : 'Add Note'
    // },

    shared() {
      return this.journal?.shared;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.realItem = item;
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$emit("deleteItem", this.editedItem);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.editedItem = defaultItem();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.editedItem = defaultItem();
    },

    save() {
      this.$emit("saveItem", this.editedItem, this.realItem);
      this.close();
    },

    onSharedChange(val) {
      this.$emit("sharedChange", val);
    },
  },
};
</script>
